import { FC } from 'react';
import Button from '../Button';
import { TFormSize } from '../TFormSize';

const ButtonCVS: FC<{ 
  disabled?: boolean,
  onClick?: () => unknown,
  size?: TFormSize,
}> = ({ 
  disabled = false,
  onClick = () => {},
  size = 'small',
}) => (
  <Button
    onClick={onClick}
    size={size}
    className="flex gap-2 items-center"
    disabled={disabled}
  >  
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" x2="12" y1="15" y2="3"/></svg>          
    .CSV
  </Button>
);


export default ButtonCVS;
