import { useEffect, useState } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPart } from "../../model/IPart";
import { IPageParams } from "../../services/IApi";
import { IPagesResponse } from "../../services/IPagesResponse";
import { partApi } from "../../services/part.api";
import { getOfferCustomerParams, getOfferInterchange,  setOfferIntechange } from "../../store/offerSlice";
import priceFormatter from "../../utils/priceFormatter";
import PartListForOffer from "./PartListForOffer";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { getErrorFromCatch } from "../../services/api";
import SelectVehicleFromPart from "./SelectVehicleFromPart";
import PartsTablePartNumber from "./PartsTablePartNumber";

export default function PartsByInterchange () {
  const dispatch = useTypedDispatch();

  // -- Get search and page parameters from global offer state 
  const interchange = useTypedSelector(getOfferInterchange());
  const pageParams = interchange.pageParams;
  const customer = useTypedSelector(getOfferCustomerParams());

  // -- State for data
  const [partsPage, setPartsPage] = useState<IPagesResponse<IPart[]>>();
  const [currentPart, setCurrentPart] = useState<IPart>();

  // -- Get loading data method
  const [getParts] = partApi.useLazyGetPartsQuery();
  const [isPending, setIsPending] = useState<boolean>(false);

  // --
  // -- Vehicle flow search
  useEffect(() => {
    if (interchange.interchangeNumber)
      (async () => {
        setIsPending(() => true);
        try {
          const parts = await getParts({
            ...interchange,            
          ...(customer?.zip ? { zip: customer?.zip } : {}),
          // if there isn't zip in the global state we need to remove distance sorting
          ...(!customer?.zip && pageParams.sort === 'distance'
            ? { pageParams: { ...pageParams, sort: '' }}
            : { pageParams: pageParams }
          )
          }).unwrap();
          setPartsPage(() => parts || {});
          setIsPending(() => false); 
        } catch (err) {
          toast.error(`${ERROR_MESSAGES['parts-loading']} ${getErrorFromCatch(err)}`, { duration: 8000 });
        } finally {
          setIsPending(() => false);
        }
      })()
    else 
      setPartsPage(() => undefined);              
  }, [interchange.interchangeNumber, pageParams, customer?.zip]);
  return (   
    <PartListForOffer
      currentPart={currentPart}
      customer={customer}
      unsetPart={() => setCurrentPart(() => undefined)}
      isPending={isPending}
      pageParams={pageParams}
      pagable={partsPage}
      updatePageParams={(newPageParams: Partial<IPageParams>) => 
        dispatch(setOfferIntechange ({
          ...interchange,
          pageParams: {
            ...interchange.pageParams,
            ...newPageParams,
          },
        }))
      }
      tbody={!partsPage || !Array.isArray(partsPage?.content)
        ? [] 
        : partsPage.content.map((part) => [
            <PartsTablePartNumber 
              part={part}
              onClick={() => setCurrentPart(() => part)}
            />,
            part?.product?.interchangeNumber || '-', // Interchange
            part?.product?.stockNumber || '-', // Stock
            part?.year || '-', // Year
            part?.carMake?.name || '-', // Make
            <SelectVehicleFromPart title={part?.carModel?.name || '-'} part={part}/>,
            part?.section?.name || '-', // Part Type
            part?.product?.condition || '-', // Mileage
            `${part?.product?.yard?.city}, ${part?.product?.yard?.state}, ${part?.product?.yard?.zip}`, // Location
            part?.distance || '-', // Distance
            priceFormatter(part?.localDiscount) || '-', // Local Discount  
            priceFormatter(part?.product?.price) || '-', // Price' },
            priceFormatter(part?.product?.shipping) || '-', // Shipping' }, !!shipping
            // priceFormatter(+price + +shipping) || '-', // Total' }, Price + shipping
          ])}
    />
  );
}
