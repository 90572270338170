import { getQuoteFilterCount, getQuoteFilterParams, getQuotePageParams } from "../store/quoteSlice";
import { useTypedSelector } from "./useTypedSelector";
import { fetchService } from "../services/fetch.service";

export function useQuote() {
  const { fetchFile } = fetchService();

  const quotePageParams = useTypedSelector(getQuotePageParams());
  const quoteFilterParams = useTypedSelector(getQuoteFilterParams());
  const quoteFilterCount = useTypedSelector(getQuoteFilterCount());

  const downloadQuoteReport = () => {
    if (!quoteFilterCount) return;

    let { sort, sortDir } = quotePageParams;
    sort = sort ? `${sort},${sortDir}` : 'created,desc';
    
    const searchParams = new URLSearchParams({
      ...quoteFilterParams,
      sort,
    });

    return fetchFile(`${process.env.REACT_APP_API_HOST}quotes/csv?${searchParams.toString()}`);
  };

  return {
    downloadQuoteReport,
  };
}