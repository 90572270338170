import { INewUserRequest, IUser, TUserRequest } from '../model/IUser';
import api from './api';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<IUser[], string>({
      query: (searchStr) => ({
          url: 'users/searchlist',
          params: { email: searchStr }
        }),
        providesTags: ['Users'],
    }),

    getUser: build.query<IUser, string>({
      query: (userId) => ({
          url: `users/${userId}`
        })
    }),

    addUser: build.mutation<IUser, INewUserRequest>({
      query: (body) => ({
          url: 'users',          
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Users']
    }),

    editUser: build.mutation<IUser, TUserRequest>({
      query: ({ id, ...body }) => ({
          url: `users/${id}`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['Users']
    }),

    deleteUser: build.mutation<IUser, string>({
      query: (userId) => ({
          url: `users/${userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Users']
    }),

  }),
});
