import StorageService from "./storage.service";
import ILogin from "./ILogin";

export function fetchService() {

  const getAccessToken = () => StorageService.getToken('access');
  const getRefreshTokenStored = () => StorageService.getToken('refresh');

  const getBaseOptions = () => ({
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  });

  const refresh = async (): Promise<boolean> => {
    const refresh = getRefreshTokenStored();
    if (!refresh) return false;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}oauth/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ refresh }),
      });

      if (response.status !== 200) return false;

      const data = await response.json();
      const { accessToken, refreshToken } = (data as ILogin)
      StorageService.setToken('access', String(accessToken));
      StorageService.setToken('refresh', String(refreshToken));

      return true;
    } catch (err) {
      return false;
    }
  }

  const fetchFile = async (url: string, params?: Record<string, string>, attempt = 0) => {
    try {
      const response = await fetch(url, {
        ...getBaseOptions(),
        ...(params ? { params } : {}),
      });
    
      if (response.status === 401 || response.status === 410)
        !attempt && (await refresh()) && (await fetchFile(url, params, 1))   
      else {
        const blob = await response.blob();
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      }
    } catch (err) {}
  }

  return {
    fetchFile,
  };
}