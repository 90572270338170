import { FC } from 'react';
import { IPart } from '../../model/IPart';
import TooltipWrapper from '../common/TooltipWrapper';

const PartsTablePartNumber: FC<{ 
  part: IPart,
  onClick?: () => unknown
}> = ({
  part,
  onClick,
}) => (
  <TooltipWrapper
    content={part?.product?.description ?? undefined}
    className="max-w-[200px]"
  >
    <a
      href="#part"
      tabIndex={-1}
      {...(onClick ? { onClick } : {})}
      className="text-blue-600 underline"
    >
      {part?.product?.partNumber || '-'}
    </a>
  </TooltipWrapper>
);

export default PartsTablePartNumber;
