import { FC, PropsWithChildren, ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';

const TooltipWrapper: FC<PropsWithChildren<{
  content: ReactNode,
  className?: string,
  classNameWrapper?: string,
  size?: 'small' | 'medium' | 'large',
}>> = ({
  children,
  content,
  className = '',
  classNameWrapper = '',
  size = 'medium',
}) => {
  const toolTipAnchor = `toolTip${String(Math.random()).slice(2)}`;

  return !children
    ? null
    : (
      <>
        <div id={toolTipAnchor} className={classNameWrapper}>
          {children}
        </div>
        <Tooltip
          anchorSelect={`#${toolTipAnchor}`}
          className={className}
          style={{ 
            backgroundColor: "black",            
            boxShadow: '1px 1px 7px rgba(0, 0, 0, .15)',
            color: "#fff",
            padding: '.5em 1em',
            fontSize: {
              small: '.6rem',
              medium: '.7rem',
              large: '1rem',
            }[size]
          }}
        >
          {content}
        </Tooltip>
      </>
  );
}

export default TooltipWrapper;
