import { NavLink } from "react-router-dom";
import type { INavLInk } from "./INavLink";
import { useAccess } from "../../hooks/useAccess";

export default function AdminNav() {
  const links: INavLInk[] = [
    { name: 'Templates', to: '/admin/template' },
    { name: 'Discounts', to: '/admin/discounts' },
    { name: 'Goals', to: '/admin/goal' },
  ];
  
  const { getPageAccess } = useAccess();

  return (
    <div className="flex gap-6">
      {links
        .filter(({ to }) => getPageAccess(to))      
        .map(({ name, to }) => (
          <NavLink
            key={name + to}
            to={to}
            className={({ isActive }) => `
              ${isActive ? 'border-primary' : 'border-transparent' } 
              border-b-2 text-gray-900 inline-flex items-center px-1 py-2 font-medium text-md
            `}
          >
            {name}
          </NavLink>
      ))}      
    </div>
  );
}
